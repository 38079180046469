import { react, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../static/css/LoginComp/LoginStyle.css';
import logo from "../static/media/logo.png";
import { loginAction } from '../utils/loginActions';



export default function LoginComp(){
    const dispatch = useDispatch();
    const loginError = useSelector(state => state.userData.loginError);
    const user = useSelector(state => state.userData.userProfile);
    const history = useHistory();
    
    if (!loginError & user.login !== ""){
        let url = "/matchs";
        history.push(url);
    }

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmitLogin = (e) => {
        e.preventDefault();
        dispatch(loginAction({'login':login, 'password': password}));
    }

    return(
        <div class="auth">
            <div class="auth-container">
                <div class="auth-logo">
                <img src={logo} />
                </div>
                <form onSubmit={handleSubmitLogin} class="auth-form">
                    <h1>Authentification</h1>
                    <div class={loginError ? "auth-input error": "auth-input"}> {/* ajouter la classe 'error' en cas d'erreur.*/}
                        <label for="login">
                        Identifiant
                        </label>
                        <input required type="login" name="login" id="name" onChange={e => setLogin(e.target.value)}/>
                        <p class="auth-error" hidden={!loginError}>Veuillez saisir votre identifiant.</p>
                    </div>
                    <div class="auth-input">
                        <label for="password">
                        Mot de passe
                        </label>
                        <input required type="password" name="password" id="password" onChange={e => setPassword(e.target.value)}/>
                    </div>
                    <button type="submit" name="submit" class="auth-btn">Se connecter</button>
                </form>
            </div>
        </div>
    );
}