import { SET_USER_DATA, SWITCH_LOGIN_ERROR_STATE } from "../constants/constants"

const initialState = {
    userProfile: {
        login: "",
        profile: ""
    },
    loginError: false,
}

export function userDataReducer(state=initialState, action) {
    switch (action.type) {
        case SET_USER_DATA:
            return {...state, userProfile: action.payload}
        
        case SWITCH_LOGIN_ERROR_STATE:
            return {...state, loginError: action.payload}

        default:
            return state
    }
};