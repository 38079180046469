import { BOOKING_STATUS_RESET, CMD_RESET, CMD_SEAT_ADD, CMD_SEAT_DELETE, CMD_SEAT_REMOVE, DECREASE_SEAT_AVAILABILITY, INCREASE_SEAT_AVAILABILITY, RESET_SEAT_AVAILABILITY } from '../constants/constants';
import { RESET_REPORTING, SALES_DATA_RESET, SET_REPORTING_REMOTE_DATA, SET_REPORTING_REMOTE_DATA_FILTERED } from '../utils/constants/constants';


export function seatAddToCommande(value) {

  return {
    type: CMD_SEAT_ADD,
    payload: value
  };
}

export function seatRemoveFromCommande(value) {

  return {
    type: CMD_SEAT_REMOVE,
    payload: value
  };
}

export function seatDeleteFromCommande(value) {

  return {
    type: CMD_SEAT_DELETE,
    payload: value
  };
}


export function resetBookingBar(){
  return {
    type: CMD_RESET
  };
}

export function resetBookingStatus(){
  return {
    type: BOOKING_STATUS_RESET
  };
}


export function resetSalesData(){
  return {
    type: SALES_DATA_RESET
  };
}

export function decreaseSeatAvailability(value) {

  return {
    type: DECREASE_SEAT_AVAILABILITY,
    payload: value
  };
}

export function increaseSeatAvailability(value) {

  return {
    type: INCREASE_SEAT_AVAILABILITY,
    payload: value
  };
}

export function resetSeatAvailability(value) {

  return {
    type: RESET_SEAT_AVAILABILITY,
    payload: value
  };
}

export function setReportingRemoteData(value){
  return {
    type: SET_REPORTING_REMOTE_DATA,
    payload: value
  };
}

export function setReportingFilteredData(value){
  return {
    type: SET_REPORTING_REMOTE_DATA_FILTERED,
    payload: value
  };
}


export function resetReportingData(){
  return {
    type: RESET_REPORTING,
  };
}