import { resetBookingBar, setReportingFilteredData, setReportingRemoteData } from "../actions/actions";
import { endpoints } from "./config";
import { SET_SALES_DATA } from "./constants/constants";
import getAxiosSession from "./http";
import {updateAvailabilities} from '../App';

export const getSalesData = (matchId, userData) =>{
    const params = {
        matchId: matchId,
        userData,
    };
    console.log('params****:', params);
    const endpoint = endpoints.sales;
    const myAxios = getAxiosSession();
    return async function(dispatch){
        try{
            const response = await myAxios.get(endpoint, {params});
            const {data} = response;
            dispatch(setSalesData(data));
        }
        catch(e){     
            dispatch(setSalesData([]));
        }
    }   
};


export const setSalesData = (data) =>({
    type: SET_SALES_DATA,
    payload: data
});


export const makeBooking = (data) =>{
    console.log('Booking of : ', data);
    const endpoint = endpoints.book;
    const myAxios = getAxiosSession();

    return async function(dispatch){
        console.log('IM IN')
        try {
            const response = await myAxios.post(endpoint, data);
            const {BookingStatus} = response.data;
            if (BookingStatus){
                dispatch(resetBookingBar())
                updateAvailabilities(data.matchId);
            }
            else{
                alert("Reservation echouée, vérifier votre commande et contacter l'administrateur !");
            }
        }
        catch(e){
            console.log('ERROR')
        }
    }
};

export const getDataFromReportingRemoteData = (data, matchFilter='', sellerFilter='', CategoryFilter='', startDate='', endDate='') =>{
    let results = {matchs: [], recette:0, jauge:0, matchList: new Set(), sellerList: new Set(), categoriesList: new Set(), soldSeats:0, totalSeats:0, filteredSoldTickets:0};
    let totalRecette = 0;
    let totalSeatsNumber = 0
    data.forEach(match => {
        if (match.matchName === matchFilter || matchFilter ===''){
            totalSeatsNumber += match.categories.length;
        }
        match.vendeurs.forEach(vendeur => {
        vendeur.bookings.forEach(booking => {
            let item = {};
            item['Match'] = match.matchName;
            item['Vendeur'] = vendeur.sellerName;
            item['Categorie'] = booking.category;
            item['Date'] = booking.date;
            item['Heure'] = booking.hour;
            item['Statut'] = 'Vendu';
            item['Prix'] = booking.price;
            let item_date = Date.parse(booking.date+'T'+booking.hour)

            results.matchList.add(match.matchName);
            results.sellerList.add(vendeur.sellerName);
            results.categoriesList.add(booking.category);
            results.soldSeats += 1;
            
            if (
                (match.matchName !== matchFilter && matchFilter !=='') 
                ||
                (booking.category !== CategoryFilter && CategoryFilter !=='')
                ||
                (vendeur.sellerName !== sellerFilter && sellerFilter !=='')
                || (startDate !== '' && item_date < Date.parse(startDate))
                || (endDate !== '' && item_date > Date.parse(endDate))
                ){
                return
            }
            totalRecette += booking.price
            results.recette += booking.price
            results.matchs.push(item);
            results.filteredSoldTickets += 1;
        });
      });
      results.totalSeats = totalSeatsNumber;
    }
    );
    return results;
  }
  
export const getRemoteReportingData = (login) => {
    const endpoint = endpoints.reporting+login;
    const myAxios = getAxiosSession();

    return async function(dispatch){
        try {
            const response = await myAxios.get(endpoint);
            const {data} = response;
            dispatch(setReportingRemoteData(data));
            dispatch(setReportingFilteredData(getDataFromReportingRemoteData(data)));
        }
        catch(e){
            console.log(e);
        }
        
    }
}