import { getBackendUrl } from "../configuration";

export const backURL = getBackendUrl();
export const backSocURL = backURL.replace('http', 'ws');

export const endpoints = {
    login: '/login',
    sales: '/get_sale_categories',
    book: '/book',
    reporting: '/get_sales_data?userId=',
    searchWebCommande: '/get_web_commande?',
    printWebCommand: '/print_web_command?',

}