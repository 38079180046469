import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { Paper } from '@material-ui/core';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';

import { AgGridReact } from 'ag-grid-react';
import AppBarComp from './AppBarComp';
import { useDispatch, useSelector } from 'react-redux';
import { getDataFromReportingRemoteData, getRemoteReportingData } from '../utils/backEndActions';
import { setReportingFilteredData } from '../actions/actions';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  labels: [
    'Vendus',
    'Resérvés',
    'Disponibles'
  ],
  datasets: [{
    label: 'My First Dataset',
    data: [300, 50, 100],
    backgroundColor: [
      'rgb(255, 99, 132)',
      'rgb(54, 162, 235)',
      'rgb(255, 205, 86)'
    ],
    hoverOffset: 4
  }]
};

const useStyles = makeStyles((theme) => ({
  chart: { 
    width: 100,
  },
  btn: {
    background: '#82ca9c',
    '&:hover': {
      backgroundColor: "#f69679"
    }
  },
  dashboard: {
    marginTop: 10,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  recette:{
    width: 350,
    height: 150,
    background: '#c0ab8e',
    color: '#043411',
    display: 'flex',
    flexFlow: 'wrap',
    alignContent: 'center',
  },
  recetteLabel: {
    width: '100%',
    textAlign: 'center',
    height: '20%',
  },
  recetteValue: {
    width: '100%',
    textAlign: 'center',
  },
  recetteIcon: {
    width: '100%',
    height: '20%'
  },
  tickets:{
    width: 350,
    height: 150,
    color: '#043411',
    display: 'flex',
    flexFlow: 'wrap',
    alignContent: 'center',
    background: '#c0ab8e',
  },
  graph:{
    width: 250,
    height: 250,
    background: '#f5f9f7',
    marginTop: 10,
    color: '#f5f9f7',
    display: 'block'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));



export default function SellsReportFilterComp() {
  
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [match, setMatch] = React.useState('');
  const [seller, setSeller] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');

  const userData = useSelector(state => state.userData);
  const reportingData = useSelector(state => state.reportingRemoteData.filteredMatchs.matchs);
  const totalRecette = useSelector(state => state.reportingRemoteData.filteredMatchs.recette);
  const dataReporting = useSelector(state => state.reportingRemoteData.filteredMatchs);
  const remoteReportingData = useSelector(state => state.reportingRemoteData.matchs);
  const dispatch = useDispatch();
  const history = useHistory();

  if (userData.userProfile.login === ''){
    history.push('/');
  }
  
  useEffect(()=> {
      dispatch(getRemoteReportingData(userData.userProfile.login));
   }, [0]
  );

  const [columnDefs] = useState([
    { field: "Match" },
    { field: "Vendeur" },
    { field: "Categorie" },
    { field: "Date" },
    { field: "Heure" },
    { field: "Statut" },
    { field: "Prix" },
  ]);     

  const gridRef = useRef();

  const handleChangeMatch = (event) => {
    setMatch(event.target.value);
  };

  const handleChangeSeller = (event) => {
    setSeller(event.target.value);
  };

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseCancel = () => {
    setMatch('');
    setSeller('');
    setCategory('');
    setStartDate('');
    setEndDate('');
    dispatch(
      setReportingFilteredData(
        getDataFromReportingRemoteData(
          remoteReportingData, '', '', ''
        )
      )
    );
    setOpen(false);
  };

  const handleCloseSubmit = () => {
    dispatch(
      setReportingFilteredData(
        getDataFromReportingRemoteData(
          remoteReportingData, match, seller, category, startDate, endDate
        )
      )
    );
    setOpen(false);
  };

  return (
    <div className="App">
        <AppBarComp userId={userData.userProfile.login}/>
      <div>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button, classes.btn}
          startIcon={<FilterListRoundedIcon/>}
          onClick={handleClickOpen}
        >
          Filtres
        </Button>
        <div className={classes.dashboard}>
          <Paper className={classes.recette} elevation={3}>
            <MonetizationOnIcon className={classes.recetteIcon}/>
            <span className={classes.recetteLabel}> Recette</span>
            <span className={classes.recetteValue}> {currencyFormat(totalRecette)}</span>
          </Paper>

          {/*
          <Paper className={classes.graph} elevation={3}>
            <Pie data={data} className={classes.chart}/>
          </Paper>
          */}
          <Paper className={classes.tickets} elevation={3}>
          <ConfirmationNumberIcon className={classes.recetteIcon}/>
            <span className={classes.recetteLabel}> Nombre de ticket vendus</span>
            <span className={classes.recetteValue}> {dataReporting.filteredSoldTickets}</span>
          </Paper>
          {/*
            <Paper className={classes.tickets} elevation={3}>
              <TrendingUpIcon className={classes.recetteIcon}/>
              <span className={classes.recetteLabel}> Jauge de remplissage</span>
              <span className={classes.recetteValue}> {Math.round(100 * dataReporting.soldSeats/dataReporting.totalSeats)}%</span>
            </Paper>
          */}
          
        </div>
        <Dialog open={open}  className={classes.test} maxWidth='lg'>
          <DialogTitle>Filtres</DialogTitle>
          <DialogContent>
            <form className={classes.container}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="demo-dialog-native">Match</InputLabel>
                <Select
                  native
                  value={match}
                  onChange={handleChangeMatch}
                  input={<Input id="demo-dialog-native" />}
                >
                  <option aria-label="None" value="" />
                  {
                    Array.from(dataReporting.matchList).map((match) => {
                        return <option key={match} value={match}>{match}</option>
                    })
                  }
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="demo-dialog-native">Vendeur</InputLabel>
                <Select
                  native
                  value={seller}
                  onChange={handleChangeSeller}
                  input={<Input id="demo-dialog-native" />}
                >
                  <option aria-label="None" value="" />
                  {
                    Array.from(dataReporting.sellerList).map((seller) => {
                        return <option key={seller} value={seller}>{seller}</option>
                    })
                  }
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="demo-dialog-native">Catégorie</InputLabel>
                <Select
                  native
                  value={category}
                  onChange={handleChangeCategory}
                  input={<Input id="demo-dialog-native" />}
                >
                  <option aria-label="None" value="" />
                  {
                    Array.from(dataReporting.categoriesList).map((category) => {
                        return <option key={category} value={category}>{category}</option>
                    })
                  }
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                  <TextField
                      id="datetime-local1"
                      onChange={handleChangeStartDate}
                      label="Plage horaire de début"
                      type="datetime-local"
                      defaultValue={startDate}
                      className={classes.textField}
                      InputLabelProps={{
                      shrink: true,
                      }}
                  />
              </FormControl>
              <FormControl className={classes.formControl}>
                  <TextField
                      id="datetime-local2"
                      onChange={handleChangeEndDate}
                      label="Plage horaire de fin"
                      type="datetime-local"
                      defaultValue={endDate}
                      className={classes.textField}
                      InputLabelProps={{
                      shrink: true,
                      }}
                  />
              </FormControl>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCloseSubmit} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <div className="ag-theme-alpine" style={{height: '55vh', width: '98vw', marginTop: 10, marginLeft:'1vw'}}>

        <AgGridReact
          rowData={reportingData}
          columnDefs={columnDefs}
          ref={gridRef}
        />
        
        </div>
      </div>
    </div>
  );
}

function currencyFormat(num) {
  num = parseFloat(num);
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' DH'
}