import { createStore, applyMiddleware } from 'redux';
import { seatCommandReducer } from '../reducers/reducers';
import thunk from 'redux-thunk'; 
import logger from 'redux-logger';

const reducer = seatCommandReducer;

export function configureStore() {
  const persistedState = localStorage.getItem('reduxState') 
                       ? JSON.parse(localStorage.getItem('reduxState'))
                       : {};
  const store = createStore(
    reducer,
    persistedState,
    applyMiddleware(thunk , logger)
  );
  store.subscribe(()=>{
    localStorage.setItem('reduxState', JSON.stringify(store.getState()))
  })
  return store;
};