import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SeatCardComp from './SeatCardComp';
import { useDispatch, useSelector } from 'react-redux';
import { getSalesData} from '../utils/backEndActions';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '65vw',
        height: '90vh',
        marginTop: 10,
        alignContent: 'flex-start',
        justifyContent:'flex-start',
        padding: '3px',
        float: 'left'
    },
  }));

  
export default function SeatsComp(){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const matchId = urlParams.get('id')

    const seatsData = useSelector(state => state.seatsData);
    const userData = useSelector(state => state.userData);
    const classes = useStyles();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getSalesData(matchId, userData));
    }, [dispatch]);
    return(
        <div className={classes.root}>
            {
                seatsData.map((item, _) => {
                    return <SeatCardComp
                             key={item.seatCategory}
                             seatCategory={item.seatCategory} 
                             seatPrice={item.seatPrice}
                             seatColor={item.seatColor}
                             seatAvailableQuantity={item.availableQty}
                            />
                })
            }
        </div>
    )
}