export const CMD_SEAT_ADD = 'ADD_SEAT_TO_COMMANDE';
export const CMD_SEAT_REMOVE = 'REMOVE_SEAT_FROM_COMMANDE';
export const CMD_SEAT_DELETE = 'DELETE_SEAT_FROM_COMMANDE';
export const CMD_RESET = 'CMD_RESET';
export const BOOKING_STATUS_RESET = 'BOOKING_STATUS_RESET';


export const DECREASE_SEAT_AVAILABILITY = 'DECREASE_SEAT_AVAILABILITY';
export const INCREASE_SEAT_AVAILABILITY = 'INCREASE_SEAT_AVAILABILITY';
export const RESET_SEAT_AVAILABILITY = 'RESET_SEAT_AVAILABILITY';
