import { RESET_REPORTING, SET_REPORTING_REMOTE_DATA, SET_REPORTING_REMOTE_DATA_FILTERED } from "../constants/constants"

const initialState = {
    matchs: [],
    filteredMatchs: {
        matchs: [],
        recette: 0,
        matchList: new Set(),
        sellerList: new Set(),
        categoriesList: new Set(),
        jauge: 0,
        soldSeats:0,
        totalSeats:0,
        filteredSoldTickets:0,
    },
}

export function reportingRemoteDataReducer(state=initialState, action) {
    switch (action.type) {
        case SET_REPORTING_REMOTE_DATA:
            return {...state, matchs: action.payload}

        case SET_REPORTING_REMOTE_DATA_FILTERED:
            return {...state, filteredMatchs: action.payload}

        case RESET_REPORTING:
            console.log('RESET', state);
            return {...state, ...initialState};
        default:
            return state
    }
};