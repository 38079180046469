import { endpoints } from "./config";
import { SET_USER_DATA, SWITCH_LOGIN_ERROR_STATE } from "./constants/constants";
import getAxiosSession from "./http";

export const loginAction = (credentials) => {
    const endpoint = endpoints.login;
    const myAxios = getAxiosSession();
    let user = {'login': '', 'profile': ''};

    return async function(dispatch){
        try {
            const response = await myAxios.post(endpoint, {identifiers: credentials});
            const {data} = response;
            dispatch(setUserData(data));
            dispatch(switchLoginState(false))
        }
        catch(e){
            dispatch(switchLoginState(true))
        }
        
    }
}

export const setUserData = (user) => ({
    type: SET_USER_DATA,
    payload: user
})

export const switchLoginState = (value) => ({
    type: SWITCH_LOGIN_ERROR_STATE,
    payload: value
})