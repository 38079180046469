export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_SALES_DATA = 'SET_SALES_DATA';
export const SWITCH_LOGIN_ERROR_STATE = 'SWITCH_LOGIN_ERROR_STATE';
export const SALES_DATA_RESET = 'SALES_DATA_RESET';


export const SET_REPORTING_REMOTE_DATA = 'SET_REPORTING_REMOTE_DATA';
export const SET_REPORTING_REMOTE_DATA_FILTERED = 'SET_REPORTING_REMOTE_DATA_FILTERED';
export const RESET_REPORTING = 'RESET_REPORTING';


