import { useEffect } from 'react';
import AppBarComp from './AppBarComp';
import BookingResumeBarComp from './BookingResumeBarComp';
import SeatsComp from './SeatsComp';
import io from "socket.io-client";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getSalesData, setSalesData} from '../utils/backEndActions';
import { getBackendUrl } from '../configuration';

let socket = io.connect(getBackendUrl());


export const updateAvailabilities = () => {
  socket.emit("booking", 'TEST');
}

function BookingPage() {
  const history = useHistory();
  const dispatch = useDispatch()
  const userData = useSelector(state => state.userData);
  if (userData.userProfile.login === ''){
    history.push('/');
  }

  useEffect(()=> {
    getMessages();
  }, [0]
  );

  const getMessages = () =>{
    socket.on("message", msg => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const matchId = urlParams.get('id');
    if (matchId !== 'undefined' && matchId.slice(15, -10) == msg){
        dispatch(getSalesData(JSON.parse(msg), userData));
    }
    });
  };

  return (
      <div className="App">
        <AppBarComp userId={userData.userProfile.login}/>
        <div>
          <SeatsComp/> 
          <BookingResumeBarComp/>
        </div>
      </div>
  );
}

export default BookingPage;
