import {React} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { decreaseSeatAvailability, seatAddToCommande, seatDeleteFromCommande } from '../actions/actions';
import { getSeatindexFromSeatStore } from '../reducers/reducers';

const useStyles = makeStyles({
  root: {
    width: 190,
    height: 110,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SeatCardComp(props) {
  const {seatCategory}  = props;  
  const seatsData = useSelector(state => state.seatsData);
  const seatData = seatsData[getSeatindexFromSeatStore(seatsData, seatCategory)];
  const dispatch = useDispatch()
  const classes = useStyles();
  const handleOnSeatClick = (e) => {
    if (seatData.availableQty > 0){
      dispatch(seatAddToCommande({'matchId': 1,'seatId': seatCategory, 'qty': 1, 'price': seatData.seatPrice, 'categoryId': seatData.categoryId}));
      dispatch(decreaseSeatAvailability({'seatCategory': seatCategory}));
    }
    else{
      alert(seatCategory + ' is not available !');
    }
  }

  return (
    <Card style={{background: seatData.seatColor, width:'190px', margin: '3px', cursor: 'pointer', color: '#ffffff'}} onClick={handleOnSeatClick} >
      <CardContent>
        <Typography variant="h5" component="h2">
          {seatCategory}
        </Typography>
        <Typography className={classes.pos} color="inherit">
          {currencyFormat(seatData.seatPrice)}
        </Typography>
        <Typography className={classes.pos} color="inherit">
            Remain Qty: {seatData.availableQty}
        </Typography>
      </CardContent>
    </Card>
  );
}

function currencyFormat(num) {
    num = parseFloat(num);
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' DH'
 }

