import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { seatAddToCommande, seatDeleteFromCommande, seatRemoveFromCommande, decreaseSeatAvailability, increaseSeatAvailability, resetSeatAvailability, resetBookingStatus } from '../actions/actions';
import { getSeatindexFromCmdStore, getSeatindexFromSeatStore } from '../reducers/reducers';
import '../static/css/BookingResumeBarComp/RecapStyle.css';
import { makeBooking } from '../utils/backEndActions';


export default function BookingResumeBarComp() {
    const dispatch = useDispatch()
    const commandList = useSelector(state => state.seatCommand);
    const seatsData = useSelector(state => state.seatsData);
    const userData = useSelector(state => state.userData);
    const isBooking = useSelector(state => state.bookingStatus);
    
    const handleAddClick = (e) => {
        if (seatsData[getSeatindexFromSeatStore(seatsData, e)].availableQty === 0){
            alert(e + ' is not available !');
            return;
        }
        dispatch(seatAddToCommande({'seatId': e}));
        dispatch(decreaseSeatAvailability({'seatCategory': e}));
    };

    const handleRemoveClick = (e) => {
        dispatch(seatRemoveFromCommande({'seatId': e}));
        dispatch(increaseSeatAvailability({'seatCategory': e}));
    };

    const handleDeleteClick = (e) => {
        dispatch(resetSeatAvailability(
            {
                'seatCategory': e, 
                'newQty': commandList[getSeatindexFromCmdStore(commandList, e)].qty
            }
        ));
        dispatch(seatDeleteFromCommande({'seatId': e}));
    };

    const handleBookClick = () =>{
        dispatch(resetBookingStatus());
        if (commandList.length === 0){
            alert('Sélectionnez une catégorie avant de proceder au paiement');
        }
        else{
            let data = {
                matchId:1,
                userData,
                commandList
            }
            dispatch(makeBooking(data));
        }
        dispatch(resetBookingStatus());
    }

    const getTotalPrice = () => {
        var total = 0;
        commandList.forEach(function(item, index) {
              total += item.price * item.qty;
            });
            return total;
        
    };

    const seatCommandListJsx = commandList.map((item)=> (
        <li className='items odd'>     
    <div className='infoWrap'> 
        <div className='cartSection'>
        {/*<img src='http://lorempixel.com/output/technics-q-c-300-300-4.jpg' alt='' className='itemImg' />*/}
          <h3>{item.seatId}</h3>
        
           <p> {item.qty} x {item.price} DH</p>
        </div>   
        <div className='prodTotal cartSection'>
          <p>{item.qty * item.price} DH</p>
        </div>
        <div className="grp-btn">
        <button className="icon-btn add-btn" id ={item.seatId +'Add'} onClick={() => {
            const id = item.seatId;
            handleAddClick(id);
        }
        }>
            <div className="add-icon"></div>
        </button>
        <button className="icon-btn add-btn" id ={item.seatId +'Remove'} onClick={() => {
            const id = item.seatId;
            handleRemoveClick(id);
        }
        }>  
        </button>
        <button className="icon-btn" id ={item.seatId +'Remove'} onClick={() => {
            const id = item.seatId;
            handleDeleteClick(id);
        }
        }>  
            <div className="delete-btn"></div>
        </button>
        </div>
      </div>
      </li>
        
    ));

    return (
        <div className="wrap cf">
  <h1 className="projTitle">Récapitulatif de la commande</h1>
  <div className="cart">
    <ul className="cartWrap">
        {seatCommandListJsx}
    </ul>
  </div>
 
  <div className="subtotal cf">
    <ul>
            <li className="totalRow final"><span className="label">Total : {getTotalPrice()} DH</span><span className="value"></span></li>
      <li className="totalRow"><button onClick={handleBookClick} disabled={ isBooking} value='Payer' className="btn continue">Payer</button></li>
    </ul>
  </div>
</div>
    );
}