import React from 'react';
import { useSelector } from 'react-redux';
import { endpoints } from '../../utils/config';
import getAxiosSession from '../../utils/http';
import AppBarComp from '../AppBarComp';
import './style.css';


const printCommande = async(cmdId, user) =>{
    const endpoint = endpoints.printWebCommand +'cmd_id=' + cmdId + '&user=' + user;
    const myAxios = getAxiosSession();
    try {
        const response = await myAxios.post(endpoint);
        return response.data;
    }

    catch(e){
        alert("Erreur lors de l'impression de la commande!")
    }
}

const getCommand = async (cmdId, cin, user) =>{
    const endpoint = endpoints.searchWebCommande + 'cin=' + cin + '&cmd_id=' + cmdId + '&user=' + user;
    const myAxios = getAxiosSession();
    try {
        const response = await myAxios.get(endpoint);
        return response.data;
    }

    catch(e){
        return {};
    }
}

export default function PickUpComp() {
    const [open, setOpen] = React.useState(false);
    const [cmdId, setCmdId] = React.useState('');
    const [cin, setCin] = React.useState('');
    const [cmdData, setCmdData] = React.useState({'details': []});

  const userData = useSelector(state => state.userData);
  
  const makeSearch = async() =>{
    const result = await getCommand(cmdId, cin, userData.userProfile.login);
    if ((result && Object.keys(result).length !== 0 && Object.getPrototypeOf(result) === Object.prototype)){
        setCmdData(result);
        setOpen(true);
    }
    else{
        setOpen(false);
    }
  }
  
  return (
      <div>
    <AppBarComp userId={userData.userProfile.login}/>
    <div class='root'>
        <div className='search_div'>
        <h1> Chercher une réservation </h1>
        <input type='text' name='cmd_id' className='search-box' placeholder='N° de Commande' onChange={(e)=>setCmdId(e.target.value)}/>
        <span> OU </span>
        <input type='text' name='cin' className='search-box' placeholder='N° de CIN' onChange={(e)=>setCin(e.target.value)}/>
        <button className='search-btn' onClick={makeSearch}>Chercher</button>
        </div>
        {
            open ?
            <div>
                <fieldset className='settings'>
                    
                    <fieldset className='info'>
                    <legend>Informations client</legend>
                        <table>
                        <tr>
                        <th>N° Commande</th>
                        <td>{cmdData['cmd_id']}</td>
                        </tr>
                        <tr>
                        <th>Statut de la commandes</th>
                        <td>{cmdData['status']}</td>
                        </tr>
                        <tr>
                        <th>Nom Client</th>
                        <td>{cmdData['nom']}</td>
                        </tr>
                        <tr>
                        <th>Prénom Client</th>
                        <td>{cmdData['prenom']}</td>
                        </tr>
                        <tr>
                        <th>Email Client</th>
                        <td>{cmdData['email']}</td>
                        </tr>
                        <tr>
                        <th>Tél Client</th>
                        <td>{cmdData['gsm']}</td>
                        </tr>
                        <tr>
                        <th>Date Commande</th>
                        <td>{cmdData['dateCmd']}</td>
                        </tr>
                        <tr>
                        <th>Heure Commande</th>
                        <td>{cmdData['heureCmd']}</td>
                        </tr>
                        <tr>
                        <th>Nombre de billets</th>
                        <td>{cmdData['qty']}</td>
                        </tr>
                        </table>
                    </fieldset>
                
                
                <fieldset className='command'>
                    <legend>Détail de la commande</legend>
                    <table>
                        <thead>
                            <tr>
                                <th>Catégorie</th>
                                <th>Prix</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                cmdData['details'].map((item) => {
                                    return(
                                        <tr>
                                            <td>{item['catégorie']}</td>
                                            <td>{item['prix']}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </fieldset>
                </fieldset>
                <button className='print-btn' onClick={() =>printCommande(cmdData['cmd_id'], 'zaki')}>Imprimer</button>
            </div>
            : 
            null
        }
  </div>
  </div>
  )
}

