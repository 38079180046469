import { useEffect } from 'react';
import AppBarComp from './Components/AppBarComp';
import MatchList from './Components/MatchListComp';
import io from "socket.io-client";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getSalesData} from './utils/backEndActions';
import { getBackendUrl } from './configuration';

let socket = io.connect(getBackendUrl());


export const updateAvailabilities = (matchId) => {
  socket.emit("booking", matchId);
}

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector(state => state.userData);
  /*if (userData.userProfile.login === ''){
    history.push('/');
  }*/

  return (
      <div className="App">
        <AppBarComp userId={userData.userProfile.login}/>
        <div>
          <MatchList/>
        </div>
      </div>
  );
}

export default App;
