import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { configureStore } from './store/configureStore';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import LoginComp from './Components/LoginComp';
import BookingPage from './Components/BookingPageComp';
import SellsReportFilterComp from './Components/SellsReportFilterComp';
import PickUpComp from './Components/PickUpComp';



const routing = (
    <Router>
        <div>
            <Route exact path="/" component={LoginComp}/>
            <Route path="/matchs" component={App}/>
            <Route path="/booking" component={BookingPage}/>
            <Route path="/report" component={SellsReportFilterComp}/>
            <Route path="/pickup" component={PickUpComp}/>
        </div>
    </Router>
)

ReactDOM.render(
<Provider store={configureStore()}>{routing}</Provider>,
 document.getElementById('root')
 );
 