import { useState, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useSelector } from 'react-redux';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useHistory } from 'react-router-dom';
import { getBackendUrl } from '../configuration';

function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

const MatchList = () => {
    const history = useHistory();
    const userData = useSelector(state => state.userData);
    if (userData.userProfile.login === ''){
        history.push('/');
    }
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);


    const autoSizeAll = useCallback((skipHeader) => {
        const allColumnIds = [];
        gridRef.current.columnApi.getAllColumns().forEach((column) => {
          allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
      }, []);

    const [columnDefs] = useState([
        { field: "nom" },
        { field: "stade" },
        { field: "competition" },
        { field: "date" },
        { field: "heure" },
    ]);     

    const goToMatchBooking = (row) => {
        console.log('click:', row.data.match_id);
        history.push('/booking/match?id=' + makeid(15) + row.data.match_id + makeid(10));
    }

    const onGridReady = useCallback((params) => {
        fetch(getBackendUrl()+'/get_matchs_list?user_id='+userData.userProfile.login)
          .then((resp) => resp.json())
          .then((data) => setRowData(data))
      }, []);
    
    return (
        <div className="ag-theme-alpine" style={{height: '100vh', width: '100vw'}}>
            <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                ref={gridRef}
                onFirstDataRendered={autoSizeAll}
                onRowDoubleClicked={goToMatchBooking}
                >
            </AgGridReact>
        </div>
    );
};

export default MatchList;