import { combineReducers } from "redux";
import { CMD_RESET, CMD_SEAT_ADD, CMD_SEAT_REMOVE, CMD_SEAT_DELETE, DECREASE_SEAT_AVAILABILITY, INCREASE_SEAT_AVAILABILITY, RESET_SEAT_AVAILABILITY, BOOKING_STATUS_RESET } from "../constants/constants";
import { userDataReducer } from "../utils/reducers/loginReducer";
import { SALES_DATA_RESET, SET_SALES_DATA } from "../utils/constants/constants";
import { reportingRemoteDataReducer } from "../utils/reducers/reportingReducer";

const initialState = {
    salesData: [],
    command: [],
    bookingStatus: false,
}

export function getSeatindexFromCmdStore(state, seatId){
    if(state.filter(function(e) { return e.seatId === seatId; }).length > 0){
        return state.findIndex((obj => obj.seatId === seatId));
    }
    return null;
}

export function seatCmdReducer(state=initialState.command, action) {

    switch (action.type) {
        case CMD_RESET:
            console.log(state);
            return []

        case CMD_SEAT_ADD:         
            const seatItemIndex = getSeatindexFromCmdStore(state, action.payload.seatId);
            if(seatItemIndex != null) {
                state[seatItemIndex].qty +=1;
                let newState = state.slice();
                return newState
            }
            else {
                return [...state, action.payload]
            }
            
            
        case CMD_SEAT_REMOVE:
            const seatItemToRemoveIndex = getSeatindexFromCmdStore(state, action.payload.seatId);
            if(seatItemToRemoveIndex != null) {
                if (state[seatItemToRemoveIndex].qty > 1){
                    state[seatItemToRemoveIndex].qty -=1;
                }
                else{
                    state.splice(seatItemToRemoveIndex, 1);
                }
                let newState = state.slice();
                return newState
            }
            return state
        
        case CMD_SEAT_DELETE:
            const seatItemToDeleteIndex = getSeatindexFromCmdStore(state, action.payload.seatId);
            if(seatItemToDeleteIndex != null) {
                state.splice(seatItemToDeleteIndex, 1);
                let newState = state.slice();
                return newState
            }
            return state

        default:
            return state
    }
};


export function getSeatindexFromSeatStore(state, seatId){
    if(state.filter(function(e) { return e.seatCategory === seatId; }).length > 0){
        return state.findIndex((obj => obj.seatCategory === seatId));
    }
    return null;
}

export function seatDataReducer(state=initialState.salesData, action) {
    switch (action.type) {

        case SALES_DATA_RESET:
            return []

        case SET_SALES_DATA:
            return action.payload

        case INCREASE_SEAT_AVAILABILITY:
            const seatItemToIncreaseQtyIndex = getSeatindexFromSeatStore(state, action.payload.seatCategory);
            if(seatItemToIncreaseQtyIndex != null) {
                state[seatItemToIncreaseQtyIndex].availableQty += 1;
                let newState = state.slice();
                return newState
            }
            return state

        case DECREASE_SEAT_AVAILABILITY:
            const seatItemToDecreaseQtyIndex = getSeatindexFromSeatStore(state, action.payload.seatCategory);
            if(seatItemToDecreaseQtyIndex != null) {
                if (state[seatItemToDecreaseQtyIndex].availableQty > 0){
                    state[seatItemToDecreaseQtyIndex].availableQty -= 1;
                }
                let newState = state.slice();
                return newState
            }
            return state
        
            case RESET_SEAT_AVAILABILITY:
                const seatItemToResetQtyIndex = getSeatindexFromSeatStore(state, action.payload.seatCategory);
                if(seatItemToResetQtyIndex != null) {
                    state[seatItemToResetQtyIndex].availableQty += action.payload.newQty;
                    let newState = state.slice();
                    return newState
                }
                return state

            
        
        default:
            return state
    }
};


export function bookingStatus(state=initialState.bookingStatus, action) {
    switch (action.type) {
        case BOOKING_STATUS_RESET:
            if(state == true){
                return false
            }
            else{
                return true
            }

        default:
            return state
    }
}

export const seatCommandReducer = combineReducers({
    seatCommand: seatCmdReducer,
    seatsData: seatDataReducer,
    userData: userDataReducer,
    reportingRemoteData: reportingRemoteDataReducer,
    bookingStatus: bookingStatus,
});